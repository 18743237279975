<template>
    <div class="inner_pages">
        <!-- <inner-sidebar /> -->
        <div class="respective_content" id="file_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Files</h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <!-- <button type="button" class="video_btn mr-3" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                    <!-- <div class="storage">
                        <span :style="`width: ${ fileStorage.used_percentage }%;`"></span>
                        <h4>Storage: {{ fileStorage.available }} {{ fileStorage.unit }} / {{ fileStorage.total }} {{ fileStorage.unit }}</h4>
                    </div> -->
                </div>
                <div class="right_section">
                    <div class="storage_wpr" v-tooltip="`Available Storage`" position="bottom">
                        <div class="storage">
                            <span :style="`width: ${ fileStorage.used_percentage }%;`"></span>
                            <h4>Storage: {{ fileStorage.available }} {{ fileStorage.unit }} / {{ fileStorage.total }} {{ fileStorage.unit }}</h4>
                        </div>
                    </div>
                    <div class="switch_btn ml-3" v-tooltip="params.archived ? 'Hide Archive' : 'Show Archive'" position="bottom">
                        <label for="preview_portal" class="switch_option capsule_btn p-0">
                            <h5 class="mr-3">Archived</h5>
                            <input type="checkbox" id="preview_portal" :true-value="1" :false-value="0" v-model="params.archived" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <ul class="ml-3">
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="left"><img src="@/assets/images/folder4.svg"></li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul class="left">
                        <li class="p-0" v-show="selectedFiles.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedFiles.length ? `${selectedFiles.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedFiles.length">
                            Bulk Action <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li @click="handleZipDownloadFiles(selectedFiles)">Download</li>
                                    <li v-if="companyUserCan('update', 'files') && params.archived == 1" @click="handleArchiveUnarchiveFile(selectedFiles, 0)">Enable</li>
                                    <li v-else-if="companyUserCan('update', 'files') && params.archived == 0" @click="handleArchiveUnarchiveFile(selectedFiles, 1)">Disable</li>
                                    <li v-if="companyUserCan('delete', 'files')" class="danger" @click="handleDeleteFile(selectedFiles)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use the files feature</li>
                                </ul>
                            </div>
                        </li>
                        <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                            <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                            <button class="search_btn" @click="searchField = !searchField;">
                                <i class="fas fa-search"></i>
                            </button>
                        </li> -->
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="filterList = !filterList"  v-click-outside="closeFileTypeDropdown">
                            {{ fileType }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="filterList ? 'active' : ''">
                                <ul>
                                    <li v-for="(type, t) in fileTypes" :key="t" :class="{'active' : type.title == fileType}" @click="sortFilesByType(type)">
                                        {{ type.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="params.per_page" :type="1" :is-dropdown="true" ref="page-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                        <!-- <li class="list_info">
                            {{ files.from ? files.from : 0 }} - {{ files.to ? files.to : 0 }} of <span>{{ files.total ? files.total : 0 }}</span>
                        </li> -->
                    </ul>
                </div>
                <div class="listing_loader" v-show="fileLoader"><quote-loader /></div>
                <div class="action_content" v-show="!fileLoader">
                    <asset-folder v-model="assetFolder" title="File" asset-type="files" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" />
                    <div class="upload_image mt-2" v-if="params.archived == 0">
                        <div :id="`file-upload-dropzone-${uId}`" class="dropzone">
                            <div class="dz-message" data-dz-message>
                                <div>
                                    <img src="@/assets/images/image2.png" class="icon">
                                    <h4>Click to Upload Files</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="playbook_list" v-if="!isArchived || folderParams.parent_id > 0">
                        <li v-for="(file, f) in files.data" :key="f">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedFiles.includes(file.id) }">
                                    <iframe v-if="file.is_doc_file" :src="`https://docs.google.com/gview?url=${file.path}&embedded=true`" class="img" scrolling="no" frameborder="0"></iframe>
                                    <img v-else :src="file.thumbnail" class="img" :class="{ 'file-icon': file.has_icon }" @error="setDefaultImage" alt="...">
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`file-${file.id}`" class="checkbox">
                                                <input type="checkbox" :id="`file-${file.id}`" :value="file.id" v-model="selectedFiles" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ file.views }} Views</li>
                                        <li>{{ file.downloads }} Downloads</li>
                                        <li>{{ moment.utc(file.created_at).local().format('ll | LT') }}</li>
                                    </ul>
                                </div>
                                <div v-tooltip="file.name">
                                    <a class="item_ttl pointer" @click="fileRename == file.id ? '' : handlePreviewFile(file)">
                                        <span :class="{ 'edit' : fileRename == file.id }" :contenteditable="fileRename == file.id ? true : false" @input="handleFileRename($event, file)">{{ file.name }}</span>
                                        &nbsp;<span class="save" v-if="fileRename == file.id" @click="saveFileName()">{{ fileRenameLoader ? 'Saving' : 'Save' }}</span>
                                    </a>
                                </div>
                                <div class="item_info">
                                    <span class="tag">{{ file.ext }}</span>
                                    <ul>
                                        <li @click="handlePreviewFile(file)"><span v-tooltip="`File Preview`"><i class="fas fa-search-plus"></i></span></li>
                                        <li @click="moreOption(file.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  file.id}">
                                                <li @click="fileRename = file.id">Rename</li>
                                                <li @click="downloadFile(file)">Download</li>
                                                <li @click="handleCopy(file.short_link)">Copy Link</li>
                                                <li v-if="companyUserCan('delete', 'files')" @click="handleDeleteFile([file.id])" class="danger">Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="empty_section" v-if="!files.total">
                        <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                        <h1>Looks like you don't have any Files yet. Click the play button to get started.</h1>
                    </div> -->
                    <div class="empty_box" v-if="!files.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any Files yet. Click the play button to get started.</h4>
                    </div>
                    <!-- <div class="pagination" v-show="files.total  && (!isArchived || folderParams.parent_id > 0)">
                        <pagination v-model="params.page" :pages="files.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div> -->
                </div>
                <div class="list_footer">
                    <ul>
                        <!-- <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="params.per_page" :type="1" :is-dropdown="true" ref="page-per-page-filter" />
                        </li> -->
                        <li>{{ files.from ? files.from : 0 }} - {{ files.to ? files.to : 0 }} of {{ files.total ? files.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="files.total  && (!isArchived || folderParams.parent_id > 0)">
                    <pagination v-model="params.page" :pages="files.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="left"><img src="@/assets/images/folder4.svg"></li>
                        <li class="switch_btn ml-auto">
                            <div class="ml-3" v-tooltip="params.archived ? 'Hide Archive' : 'Show Archive'" position="bottom">
                                <label for="preview_portal" class="switch_option capsule_btn p-0">
                                    <h5 class="mr-3">Archived</h5>
                                    <input type="checkbox" id="preview_portal" :true-value="1" :false-value="0" v-model="params.archived" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedFiles.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedFiles.length ? `${selectedFiles.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedFiles.length">
                            Bulk Action <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li @click="handleZipDownloadFiles(selectedFiles)">Download</li>
                                    <li v-if="companyUserCan('update', 'files') && params.archived == 1" @click="handleArchiveUnarchiveFile(selectedFiles, 0)">Enable</li>
                                    <li v-else-if="companyUserCan('update', 'files') && params.archived == 0" @click="handleArchiveUnarchiveFile(selectedFiles, 1)">Disable</li>
                                    <li v-if="companyUserCan('delete', 'files')" class="danger" @click="handleDeleteFile(selectedFiles)">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="filterList2 = !filterList2"  v-click-outside="closeFileTypeDropdown2">
                            {{ fileType }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="filterList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(type, t) in fileTypes" :key="t" :class="{'active' : type.title == fileType}" @click="sortFilesByType(type)">
                                        {{ type.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <file-preview v-model="fileDetails" :file="editedFile" :refresh-files="refreshFiles" />
        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/229f01b349364fd088521a2bc4dc4e92?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const FilePreview = defineAsyncComponent(() => import('@/pages/file/components/Preview'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))

    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import moment from 'moment'
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'

    export default {
        name: 'File Index',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                params: {
                    page: 1,
                    per_page: 6,
                    search: '',
                    archived: 0,
                    field: 'id',
                    order: 'desc',
                    file_type: '',
                    folder_id: null,
                },
                filters: [
                    { title: 'Date Added', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                    { title: 'Newest', field: 'id', order: 'desc'},
                    { title: 'Oldest', field: 'id', order: 'asc'},
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                ],
                fileTypes: [
                    {
                        title: 'All',
                        type: ''
                    },
                    {
                        title: 'Image',
                        type: 'img'
                    },
                    {
                        title: 'Video',
                        type: 'video'
                    },
                    {
                        title: 'Audio',
                        type: 'audio'
                    },
                    {
                        title: 'Document',
                        type: 'doc'
                    },
                    {
                        title: 'Excel',
                        type: 'excel'
                    },
                    {
                        title: 'Powerpoint',
                        type: 'ppt'
                    },
                    {
                        title: 'PDF',
                        type: 'pdf'
                    },
                    {
                        title: 'Zip',
                        type: 'zip'
                    },
                    {
                        title: 'Others',
                        type: 'others'
                    },
                ],
                actionList: false,
                actionList2: false,
                filterList: false,
                filterList2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                isTyping: false,
                filterTitle: 'Date Added',
                fileType: 'All',
                selectedFiles: [],
                fileDetails: false,
                fileRename: 0,
                moment,
                moreOptionDropdown: 0,
                editedFile: {},
                fileRenameLoader: false,
                isMoreOption: false,
                uId: Date.now(),
                assetFolder: false,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                assetFolderWatcher: 0,
                isLastBreadcrumb: false,
                showContent: false,
                searchField: false,
                mobile_filter: false,
            };
        },

        components: {
            // InnerSidebar,
            FilePreview,
            AssetFolder,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getFiles(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getFiles(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.selectedFiles = [];

                vm.getFiles(vm.params);
            },

            'params.archived' () {
                const vm = this;

                vm.params.page = 1;
                vm.selectedFiles = [];

                vm.getFiles(vm.params);
            },

            fileDetails (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed:mapState({
            fileStorage: state => state.authModule.fileStorage,
            authToken: state => state.authModule.authToken,
            files: state => state.fileModule.files,
            fileLoader: state => state.fileModule.fileRefreshLoader,
            isArchived: state => state.folderModule.isArchived,
            isPreviousFolder: state => state.folderModule.isPreviousFolder,
            isAssetFolder: state => state.folderModule.isAssetFolder,
            folderId: state => state.folderModule.folderId,
            folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
        }),

        mounted () {
            const vm = this;

            if (vm.isPreviousFolder) {
                vm.folderParams.archived  = vm.isArchived ? 1 : 0;
                vm.folderParams.parent_id = vm.folderId ? vm.folderId : 0;
                vm.isLastBreadcrumb       = true;
                vm.assetFolder            = vm.isAssetFolder ? true : false;
            }

            vm.setIsPreviousFolder(false);

            vm.getFiles(vm.params);
            vm.initDropzone();

            document.getElementById('file_index').onclick = function() {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }

            // vm.setIsArchivedStatus(false);
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                getFiles: 'fileModule/getFiles',
                deleteFiles: 'fileModule/deleteFiles',
                renameFile: 'fileModule/renameFile',
                archiveFiles: 'fileModule/archiveFiles',
                downloadFile: 'fileModule/downloadFile',
                downloadZip: 'fileModule/downloadZip',
                uploadFile: 'fileModule/uploadFile',
                validateFileName: 'fileModule/validateFileName',
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),

            ...mapMutations({
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList         = false;
                vm.bulkActionDropdown = false;
                vm.modeFilterDropdown = false;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            closeFileTypeDropdown () {
                const vm = this;

                vm.filterList = false;
            },
            closeFileTypeDropdown2 () {
                const vm = this;

                vm.filterList2 = false;
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle  = filter.title;
                vm.params.field = filter.field;
                vm.params.order = filter.order;
                vm.selectedPage = [];

                vm.getFiles(vm.params);
            },

            sortFilesByType (filter) {
                const vm = this;

                vm.fileType         = filter.title;
                vm.params.page      = 1;
                vm.params.file_type = filter.type;

                vm.getFiles(vm.params);
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            initDropzone () {
                const vm = this;

                const dropzone = new Dropzone(`#file-upload-dropzone-${vm.uId}`, {
                    url: process.env.VUE_APP_API_URL+'/api/v3/file/upload',
                    uploadMultiple: false,
                    autoProcessQueue: false,
                    paramName: 'images',
                    createImageThumbnails: true,
                    maxFiles:1,
                    init: function() {
                        this.hiddenFileInput.removeAttribute('multiple');
                    },
                    headers: {
                        'Authorization': 'Bearer '+vm.authToken.access_token,
                        'accessed-location-id': vm.authToken.accessed_location_id,
                        'company-admin-id': vm.authToken.company_admin_id,
                    },
                    addedfiles: function (files) {
                        const file  = files[0];
                        const form  = new FormData;

                        form.append('file', file);
                        form.append('filename', file.name);

                        vm.validateFileName({ name: file.name }).then((resp) => {
                            if (resp.data.status) {
                                const options = Helper.swalConfirmOptions('Are you sure?', `A file with the name ${file.name} already exists in your OM files. Add anyway?`, 'Yes', false);

                                Swal.fire(options).then((result) => {
                                    if (result.isConfirmed) {
                                        file.previewElement.classList.add("dz-processing");
                                        vm.uploadFile(form).then((f) => {
                                            if (f) {
                                                vm.getFiles(vm.params);
                                                vm.refreshAuth();
                                            }

                                            file.previewElement.classList.add("dz-success");
                                            file.previewElement.classList.add("dz-complete");
                                            file.previewElement.parentNode.removeChild(file.previewElement);
                                            const el = document.getElementById(`file-upload-dropzone-${vm.uId}`);
                                            el.classList.remove('dz-started');
                                            el.classList.remove('dz-max-files-reached');

                                            vm.resetDropzone(dropzone);
                                        });
                                    } else {
                                        if (file.previewElement != null && file.previewElement.parentNode != null) {
                                            file.previewElement.parentNode.removeChild(file.previewElement);
                                            const el = document.getElementById(`file-upload-dropzone-${vm.uId}`);
                                            el.classList.remove('dz-started');
                                            el.classList.remove('dz-max-files-reached');
                                        }

                                        vm.resetDropzone(dropzone);
                                    }
                                });

                            } else {
                                file.previewElement.classList.add("dz-processing");
                                vm.uploadFile(form).then((f) => {
                                    if (f) {
                                        vm.getFiles(vm.params);
                                        vm.refreshAuth();
                                    }

                                    file.previewElement.classList.add("dz-success");
                                    file.previewElement.classList.add("dz-complete");
                                    file.previewElement.parentNode.removeChild(file.previewElement);
                                    const el = document.getElementById(`file-upload-dropzone-${vm.uId}`);
                                    el.classList.remove('dz-started');
                                    el.classList.remove('dz-max-files-reached');

                                    vm.resetDropzone(dropzone);
                                });
                            }
                        }).catch((err) => {
                            Toastr.handleServerError(err);
                        });
                    },
                });
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page    = page;
                vm.selectedFiles  = [];

                vm.getFiles(vm.params);
            },

            setDefaultImage (event) {
                event.target.src = require('@/assets/images/thumb/default-cover-3-2.svg');
            },

            moreOption (id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            refreshFiles (silent = false) {
                const vm = this;

                if (!silent) {
                    vm.params.page    = 1;
                    vm.params.search  = '';
                }

                vm.params.silent  = silent;
                vm.selectedFiles  = [];

                vm.getFiles(vm.params);
            },

            handleDeleteFile (ids) {
                const vm = this;

                if (ids.length) {
                    vm.deleteFiles(ids).then((result) => {
                        if (result) {
                            vm.refreshFiles();
                        }
                    });
                } else {
                    Toastr.error('Please select at least one file');
                }
            },

            handleFileRename(event, file) {
                const vm = this;

                vm.editedFile = {
                    id: file.id,
                    name: event.target.innerText,
                };
            },

            saveFileName () {
                const vm = this;
                vm.fileRenameLoader = true;

                vm.renameFile(vm.editedFile).then((result) => {
                    if (result) {
                        vm.fileRename = 0;
                        vm.editedFile = {};
                    }

                    vm.fileRenameLoader = false;
                });
            },

            handleArchiveUnarchiveFile (ids, status = 1) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to ${status ? 'disable' : 'enable'} ${ids.length > 1 ? 'these files' : 'this file'} for all contacts and all public views`);

                    options.preConfirm = function () {
                                            return vm.archiveFiles({ ids, status }).then((result) => {
                                                if (result) {
                                                    vm.refreshFiles();
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one file');
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            resetSelected () {
                const vm = this;

                vm.selectedFiles = [];
            },

            handleZipDownloadFiles (ids) {
                const vm      = this;
                const files   = vm.files.data.filter((file) => { return ids.includes(file.id) });
                const params  = { files, action: vm.resetSelected };

                vm.downloadZip(params);
            },

            handlePreviewFile (file) {
                const vm = this;

                vm.editedFile   = file;
                vm.fileDetails  = true;
            },

            resetDropzone (dropzone) {
                const vm = this;

                dropzone.destroy();
                vm.uId = Date.now();

                setTimeout(function () {
                    vm.initDropzone();
                }, 100);
            },

            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.params.folder_id       = folder.parent_id ? folder.parent_id : null;

                vm.refreshFile();
            },

            refreshFile () {
                const vm = this;

                vm.params.page     = 1;
                vm.params.search   = '';
                vm.selectedFiles   = [];

                vm.getFiles(vm.params);
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            },
        },
    }
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
        align-items: center;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .acton_header .right_section {
        display: flex;
        align-items: center;
    }

    .playbook_list li .tag {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
        border-radius: 3px;
        background: #2f7eed;
        text-transform: uppercase;
    }

    .playbook_list {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .action_content .upload_image .dropzone,
    .action_content .upload_image label {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .action_content .upload_image .dropzone .icon,
    .action_content .upload_image label .icon {
        height: 70px;
        width: 70px;
    }

    .upload_image .dropzone {
        border: 1.2px dashed #95BFFF;
        background: #FAFCFD;
        display: block;
        padding: 25px 25px 15px 25px;
    }

    .playbook_list>li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2C3E50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    .plybook_item .item_img .img.file-icon{
        padding: 15% 35%;
        object-fit: contain;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info>ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info>ul>li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics,
    .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    @keyframes dropMove2 {
        from {
            margin-bottom: 10px;
            opacity: 0;
        }

        to {
            margin-bottom: 1px;
            opacity: 1;
        }
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .modal.share_playbook .modal_header {
        padding: 20px 30px 0;
    }

    .modal.share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .folder_list {
        display: flex;
        margin-top: 20px;
    }

    .folder_list li {
        width: 33.333%;
    }

    .sorting_card li {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
    }

    .sorting_card li img {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }

    .sorting_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .sorting_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .sorting_card li:hover {
        background: #fff;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13);
    }

    .result_wpr.small .actions>ul {
        height: 35px;
    }

    /* .result_wpr.small .actions>ul li,
    .result_wpr.small .actions>ul li input {
        padding: 7px 10px;
        font-size: 14px !important;
    } */

    .result_wpr.small .actions>ul li .dropdown_wpr {
        width: 100%;
    }

    .result_wpr.small table td {
        padding: 12px;
        font-size: 13px;
        line-height: 16px;
    }

    .result_wpr.small table th {
        padding: 7px 12px;
        /* font-size: 13px;
        line-height: 16px; */
    }

    .result_wpr.small table td .user_img,
    .result_wpr.small table td .user_img img {
        width: 30px;
        height: 30px;
    }

    .result_wpr.small table td h4 {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
    }

    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-envelope {
        color: #f2a31d;
    }

    .action_list li i.fa-comment {
        color: #2f7eed;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }

    .action_list li i.fa-search-plus {
        color: #32373b;
    }

    .folders_list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;
    }

    .folders_list li {
        padding: 0 5px;
    }

    .folders_list .folder_item {
        border: 1px solid #E9E9E9;
        padding: 10px 15px;
        border-radius: 9px;
        background: #fff;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        font-size: 13px;
        line-height: 17px;
        color: #5A5A5A;
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        height: 100%;
        cursor: pointer;
    }

    .folders_list .folder_item img {
        max-width: 30px;
        height: auto;
        margin-right: 10px;
    }

    .folders_list .folder_item:hover {
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.13)
    }

    .folders_list .folder_item .drp_wrapper {
        display: none;
    }

    .folders_list .folder_item .dropdown {
        margin-left: 20px;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper {
        display: block;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul {
        padding: 5px 0;
    }

    .folders_list .folder_item .dropdown.active .drp_wrapper ul li {
        font-size: 10px;
        line-height: 12px;
        padding: 4px 8px;
        text-align: left;
    }

    .modal.redirect_modal .modal_container {
        height: 420px;
    }

    .modal.redirect_modal .section_ttl {
        font-size: 16px;
        line-height: 20px;
        color: #5a5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 0 10px;
    }

    .modal.redirect_modal .section_ttl span {
        padding: 0 10px;
        background: #fff;
    }

    .modal.redirect_modal .section_ttl:after {
        content: '';
        position: absolute;
        left: 7px;
        right: 7px;
        top: 50%;
        border-bottom: 1px solid #e9e9e9;
        z-index: -1;
    }

    .redirect_list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .redirect_list li {
        width: 33.333%;
        padding: 5px 8px;
    }

    .redirect_list li label h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 10px;
        cursor: pointer;
        background: rgba(242, 163, 29, 0.05);
        border: 1px solid rgba(242, 163, 29, 0.3);
        color: #757575;
        filter: grayscale(1);
    }

    .redirect_list li label input[type=radio]:checked~h5 {
        filter: grayscale(0);
        color: #f2a31d;
    }

    .redirect_action {
        width: 100%;
        padding: 0 8px;
    }

    .modal.redirect_modal .action_wpr {
        margin-top: auto;
    }

    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        list-style-type: decimal;
        margin-left: 17px;
        padding: 10px 0 10px 5px;
        border-bottom: 1px solid #e9e9e9;
    }

    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }
    .storage_wpr{
        display: flex;
        align-items: center;
    }
    .storage {
        padding: 4px 10px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 14px;
        position: relative;
        width: 180px;
        overflow: hidden;
    }

    .storage h4 {
        font-size: 11px;
        line-height: 14px;
        color: #757575;
        font-weight: 400;
        position: relative;
        z-index: 1;
    }

    .storage span {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .plybook_item .item_ttl span.edit {
        cursor: auto;
    }

    .plybook_item .item_ttl span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li:not(.switch_btn) {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 15px;
        }
        .acton_header .left_section .search_area{
            margin-left: auto;
        }
        .acton_header .right_section .storage_wpr{
            margin-right: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section ul, .acton_header .right_section .switch_btn{
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 767px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(max-width: 499px){
        .storage{
            width: 160px;
        }
        .storage h4{
            font-size: 10px;
            line-height: 12px;
            font-weight: 500;
        }
    }
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
